.slide-pane {
    display: flex;
    flex-direction: column;
    background: #fff;
    min-width: 100px;
    height: 100%;
    max-width: 100%;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
    transition: transform 0.5s;
    will-change: transform;
}

.slide-pane:focus {
    outline-style: none;
}

.slide-pane_from_right {
    margin-left: auto;
    transform: translateX(100%);
}

.slide-pane_from_right.content-after-open {
    transform: translateX(0%);
}

.slide-pane_from_right.content-before-close {
    transform: translateX(100%);
}

.slide-pane_from_left {
    margin-right: auto;
    transform: translateX(-100%);
}

.slide-pane_from_left.content-after-open {
    transform: translateX(0%);
}

.slide-pane_from_left.content-before-close {
    transform: translateX(-100%);
}

.slide-pane_from_bottom {
    height: 90vh;
    margin-top: 10vh;
    transform: translateY(100%);
}

.slide-pane_from_bottom.content-after-open {
    transform: translateY(0%);
}

.slide-pane_from_bottom.content-before-close {
    transform: translateY(100%);
}

.slide-pane__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.slide-pane__header {
    display: flex;
    flex: 0 0 64px;
    align-items: center;
    background: #ebebeb;
    height: 64px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.slide-pane__title-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 32px;
    min-width: 0;
}

.slide-pane .slide-pane__title {
    font-size: 18px;
    font-weight: normal;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
}

.slide-pane__close {
    margin-left: 24px;
    padding: 16px;
    opacity: 0.7;
    cursor: pointer;
}

.slide-pane__close svg {
    width: 12px;
    padding: 0;
}

.slide-pane__content {
    position: relative;
    overflow-y: auto;
    padding: 1.5rem 1rem 5.625rem;
    flex: 1 1 auto;
}

.slide-pane__subtitle {
    font-size: 12px;
    margin-top: 2px;
}
