.overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1200;
    transform: translate(-50%, -50%);
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 8px;
    background-color: white;
    transform: translate(-50%, -50%) scale(1);
    transition-property: transform;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
