.react-datepicker-wrapper {
    @apply text-sm;

    .react-datepicker__input-container {
        &:after {
            @apply absolute w-4 h-4 block right-2 top-3 content-calendar -z-10;
        }

        input {
            @apply cursor-pointer box-border w-full h-10 text-sm border-gray-10 px-3 bg-transparent relative z-10 rounded;

            &.react-datepicker-ignore-onclickoutside {
                @apply border-blue ring-1 ring-blue ring-offset-0;
            }

            &.error {
                @apply border-red-600 ring-red-600 ring-1;
            }
        }
    }
}

.react-datepicker__time-container {
    @apply border-l-neutral-200;

    .react-datepicker__time {
        .react-datepicker__time-box {
            ul.react-datepicker__time-list {
                li.react-datepicker__time-list-item--selected {
                    @apply bg-blue;

                    &:hover {
                        @apply bg-blue text-white;
                    }
                }

                li.react-datepicker__time-list-item {
                    @apply h-9 flex justify-center items-center;

                    &:hover {
                        @apply bg-blue text-white;
                    }
                }
            }
        }
    }
}

.react-datepicker-popper {
    @apply z-20;

    .qualifiction_datepicker {
        z-index: 40;
    }
    .react-datepicker {
        @apply font-inter rounded-md border-gray-10 text-sm p-3;

        .react-datepicker__current-month,
        .react-datepicker-time__header,
        .react-datepicker-year-header {
            @apply text-sm font-medium;
        }

        .react-datepicker__current-month {
            @apply capitalize;
        }

        .react-datepicker__header {
            @apply bg-white rounded-none border-0 p-0;
        }

        .react-datepicker__navigation {
            @apply top-1 h-8;
        }

        .react-datepicker__navigation--previous {
            @apply left-3;
        }

        .react-datepicker__navigation--next {
            @apply right-3;
        }

        .react-datepicker__navigation--next--with-time {
            @apply right-24;
        }

        .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__month-year-read-view--down-arrow,
        .react-datepicker__navigation-icon::before {
            @apply top-4 border-t-2 border-r-2;
        }

        .react-datepicker__day-name {
            @apply text-gray;
        }

        .react-datepicker__day {
            @apply bg-gray-100;
        }

        .react-datepicker__day--weekend {
            @apply text-red-500;
        }

        .react-datepicker__day--outside-month {
            @apply text-gray-10 bg-transparent;
        }

        .react-datepicker__day-names,
        .react-datepicker__week {
            @apply flex items-center justify-center;
        }

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            @apply flex w-9 h-9 p-0 m-0 items-center justify-center;
        }

        .react-datepicker__day:not(.react-datepicker__day--disabled):hover,
        .react-datepicker__month-text:not(.react-datepicker__day--disabled):hover,
        .react-datepicker__quarter-text:not(.react-datepicker__day--disabled):hover,
        .react-datepicker__year-text:not(.react-datepicker__day--disabled):hover {
            @apply rounded-none bg-blue text-white;
        }

        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--selected,
        .react-datepicker__year-text--in-selecting-range,
        .react-datepicker__year-text--in-range {
            @apply rounded-none bg-blue text-white;
        }
    }

    &[data-placement^='top'] .react-datepicker__triangle::before,
    &[data-placement^='bottom'] .react-datepicker__triangle::before {
        @apply border-b-gray-10;
    }

    &[data-placement^='bottom'] .react-datepicker__triangle::before {
        @apply border-b-gray-10;
    }

    &[data-placement^='top'] .react-datepicker__triangle::before {
        @apply border-t-gray-10;
    }
}

.react-datepicker__day--weekend {
    @apply text-red-500;
}

.react-datepicker__day--disabled.react-datepicker__day--weekend {
    color: #ccc;
}

.deffered_datepicker {
    .react-datepicker__month-select {
        font-size: 14px;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 2px;
    }

    .react-datepicker__year-select {
        font-size: 14px;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 2px;
    }
}

.react-datepicker__header--time {
    margin-bottom: 10px;
    margin-right: 10px;
}

.qualifiction_datepicker {
    padding-top: 0;

    & .react-datepicker__month {
        background-color: #fff;
    }

    & .react-datepicker__month-select {
        font-size: 14px;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 2px;
    }

    & .react-datepicker__year-select {
        font-size: 14px;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 2px;
    }

    & .react-datepicker__header {
        padding-top: 8px;
    }

    & .react-datepicker__navigation-icon--next::before {
        top: 14px;
    }

    & .react-datepicker__navigation-icon--previous::before {
        top: 14px;
    }

    & .react-datepicker__current-month {
        margin-bottom: 6px;
    }
}

.chartCalendar {
    & .react-datepicker-wrapper .react-datepicker__input-container input {
        background-color: white;
        z-index: 0;
        height: 30px;
    }

    & .react-datepicker__day--keyboard-selected {
        color: blue;
    }
}
