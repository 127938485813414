// Multi select
.select {
    input:not([type='checkbox']):focus {
        @apply ring-0;
    }

    .filter__control {
        @apply relative z-[17] h-10 border border-gray-10 rounded cursor-pointer;

        &.filter__control--is-focused {
            @apply border-blue;
        }
    }

    .filter__value-container {
        @apply h-full;
    }

    .filter__input:focus {
        @apply ring-transparent;
    }

    .filter__menu {
        @apply z-50;
    }

    .filter__value-container--is-multi {
        @apply flex flex-nowrap relative gap-0.5 py-0;

        .filter__multi-value {
            @apply shrink-0 max-w-[75%] m-0;
        }

        input {
            @apply absolute;
        }
    }

    .filter__option {
        @apply text-gray-20 cursor-pointer;

        &.filter__option--is-selected {
            @apply bg-white text-gray-20;
        }

        &.filter__option--is-focused {
            @apply bg-blue-10;
        }
    }

    .filter__indicator {
        @apply text-gray-50;

        &:hover {
            @apply text-gray-50;
        }
    }

    .filter__indicator-separator {
        @apply bg-gray-70 my-2;
    }

    .filter__placeholder {
        @apply text-gray-50 m-0 ml-0.5 absolute w-full line-clamp-1;
    }
}

// Simple select
.select {
    .simple_filter__control {
        @apply relative z-[17] h-10 border border-gray-10 rounded cursor-pointer;

        &.simple_filter__control--is-focused {
            @apply border-blue;
        }
    }

    .simple_filter__value-container {
        @apply h-full;
    }

    .simple_filter__input:focus {
        @apply ring-transparent;
    }

    .simple_filter__menu {
        @apply z-50;
    }

    .simple_filter__option {
        @apply text-gray-20 cursor-pointer;

        input {
            @apply opacity-0 w-0;
        }

        &.simple_filter__option--is-focused {
            @apply bg-blue-10;
        }

        &.simple_filter__option--is-selected {
            @apply bg-blue-100;
        }

        &.simple_filter__option--is-disabled {
            @apply text-gray-300;
        }
    }

    .simple_filter__placeholder {
        @apply text-gray-50 m-0 ml-0.5 line-clamp-1;
    }

    .simple_filter__indicator {
        @apply text-gray-50;

        &:hover {
            @apply text-gray-50;
        }
    }
}

.select.addFilterPlh .filter__placeholder {
    font-size: 0.8rem;
    opacity: 0.8;
}

.descriptionAddit {
    font-size: 0.9rem;
    border-color: rgba($color: #000000, $alpha: 0.2);
}

.additFilter .filter__control {
    height: 100%;

    .filter__value-container div {
        .filter__multi-value__label {
            width: 100%;
        }
    }
}

.controlSelect .filter__control {
    min-height: 30px;
    height: 1.5rem;
}

.controlSelect .filter__indicator {
    @apply p-0;
}

.controlSelect.hideRemoveSelectButton .filter__multi-value__remove {
    @apply hidden;
}

.controlSelect .filter__option.filter__option--is-disabled {
    color: lightgray;
}

.nodeSelectSize .filter__menu-list {
    height: 200px !important;
    max-height: 200px;
}
